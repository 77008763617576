import React, {useState} from "react";
import {IResourceComponentsProps} from "@refinedev/core";

import {ICassaProfessionale} from "interfaces";
import {Edit, useForm} from "@refinedev/antd";
import {Form, Input} from "antd";

export const CassaProfessionaleEdit: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<ICassaProfessionale>({});



    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    label="Descrizione"
                    name="descCassaProfessionale"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
