import {
    Refine,
    Authenticated,
} from '@refinedev/core';
import {RefineKbarProvider} from '@refinedev/kbar';

import {
    ErrorComponent
    , notificationProvider, RefineThemes
    , ThemedLayoutV2
} from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';

import {DataProvider} from '@refinedev/strapi-v4';
import {BrowserRouter, Route, Routes, Outlet} from 'react-router-dom';
import routerBindings, {NavigateToResource, CatchAllNavigate, UnsavedChangesNotifier} from '@refinedev/react-router-v6';
import {ColorModeContextProvider} from './contexts/color-mode';
import {authProvider, axiosInstance} from './authProvider';
import {API_URL, TOKEN_KEY} from './constants';
import {ConfigProvider, Spin, theme} from 'antd';
import React from 'react';
import {CustomSider} from './components/sider';
import {Header} from './components';
import {
    AuditOutlined,
    CalendarOutlined,
    CalendarTwoTone,
    DashboardTwoTone, ExperimentTwoTone,
    MenuUnfoldOutlined,
    PaperClipOutlined,
    PropertySafetyFilled,
    RobotOutlined, SafetyOutlined,
    StarOutlined,
    TagOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {OffLayoutArea} from "./components/offLayoutArea";
import {AgendaPage} from "./pages/agenda";
import './style.css';
import {AuthPage} from "./pages/auth";
import {ForgotPasswordPage, RegisterPage, UpdatePasswordPage} from "./pages/auth/components";
import LiveEventSocketCtxProvider from "./contexts/socketContext/provider";
import {newEnforcer} from 'casbin';
import jwt_decode from 'jwt-decode';
import {adapter, model} from './accessControl';
import {DashboardPage} from "./pages/dashboard";
import {TotaleNotificheProvider} from "./totaleNotificheProvider";
import {ClienteEdit, ClientiList, CreateCliente} from "./pages/clienti";
import {CreateRegistrazioni, RegistrazioniEdit, RegistrazioniList} from "./pages/registrazioni";
import {DatiAziendaEdit, DatiAziendaList} from "./pages/datiAzienda";
import {CreateUser, UserEdit, UsersList} from "./pages/users";
import {CreateListinoBase, ListinoBaseEdit, ListinoBaseList} from "./pages/listinoBase";
import {NaturaEdit, NaturaList} from "./pages/natura";
import {CreateNatura} from "./pages/natura/create";
import {CassaProfessionaleEdit, CassaProfessionaleList, CreateCassaProfessionale} from "./pages/cassaProfessionale";
import {
    CreatePosizionePrevidenziale,
    PosizionePrevidenzialeEdit,
    PosizionePrevidenzialeList
} from "./pages/posizionePrevidenziale";
import {CreateStatoRegistrazione, StatoRegistrazioneEdit, StatoRegistrazioneList} from "./pages/statoRegistrazione";
import {CreateTipoContabilita, TipoContabilitaEdit, TipoContabilitaList} from "./pages/tipoContabilita";
import {CreateTipoDichiarazione, TipoDichiarazioneEdit, TipoDichiarazioneList} from "./pages/tipoDichiarazione";
import {CreateTipoLiquidazioneIva, TipoLiquidazioneIvaEdit, TipoLiquidazioneIvaList} from "./pages/tipoLiquidazioneIva";
import {AnalisiCreate, AnalisiEdit, AnalisiList, AnalisiShow} from "./pages/analisi";

function App() {
    const myResource = [
        {
            name: "dashboard",
            list: "/dashboard",
            meta: {label: "Dashboard", icon: <DashboardTwoTone/>},
        },
        {
            name: "analisis",
            list: "/analisis",
            edit: "/analisis/edit/:id",
            create: "/analisis/create",
            show: "/analisis/show/:id",
            meta: {
                label: "Analisi",
                icon: <ExperimentTwoTone/>,
            },
        },
        {
            name: "agendas",
            list: "/agendas",
            meta: {label: "Agenda", icon: <CalendarTwoTone/>},
        },
        {
            name: "clientes",
            list: "/clientes",
            edit: "/clientes/edit/:id",
            create: "/clientes/create",
            meta: {label: "Clienti", icon: <RobotOutlined/>},
        },
        {
            name: "registraziones",
            list: "/registraziones",
            create: "/registraziones/create",
            meta: {label: "Registrazioni", icon: <RobotOutlined/>},
        },
        {name: 'Anagrafiche', meta: {icon: <MenuUnfoldOutlined/>}},
        {
            name: "dati-aziendas",
            list: "/dati-aziendas",
            edit:'/dati-aziendas/edit/:id',
            create: "/dati-aziendas/create",
            meta: {label: "Dati Azienda", icon: <AuditOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "users",
            list: "/users",
            edit:'/users/edit/:id',
            create: "/users/create",
            meta: {label: "Utenti", icon: <UserOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "listino-bases",
            list: "/listino-bases",
            edit:'/listino-bases/edit/:id',
            create: "/listino-bases/create",
            meta: {label: "Listino Base", icon: <RobotOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "naturas",
            list: "/naturas",
            edit:'/naturas/edit/:id',
            create: "/naturas/create",
            meta: {label: "Natura", icon: <TagOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "cassa-professionales",
            list: "/cassa-professionales",
            edit:'/cassa-professionales/edit/:id',
            create: "/cassa-professionales/create",
            meta: {label: "Cassa Professionale", icon: <TagOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "posizione-previdenziales",
            list: "/posizione-previdenziales",
            edit:'/posizione-previdenziales/edit/:id',
            create: "/posizione-previdenziales/create",
            meta: {label: "Posizione Providenziale", icon: <PaperClipOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "stato-registraziones",
            list: "/stato-registraziones",
            edit:'/stato-registraziones/edit/:id',
            create: "/stato-registraziones/create",
            meta: {label: "Stato Registrazioni", icon: <StarOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "tipo-contabilitas",
            list: "/tipo-contabilitas",
            edit:'/tipo-contabilitas/edit/:id',
            create: "/tipo-contabilitas/create",
            meta: {label: "Tipo Contabilità", icon: <PropertySafetyFilled/>,  parent: 'Anagrafiche'},
        },
        {
            name: "tipo-dichiaraziones",
            list: "/tipo-dichiaraziones",
            edit:'/tipo-dichiaraziones/edit/:id',
            create: "/tipo-dichiaraziones/create",
            meta: {label: "Tipo Dichiarazione", icon: <SafetyOutlined/>,  parent: 'Anagrafiche'},
        },
        {
            name: "tipo-liquidazione-ivas",
            list: "/tipo-liquidazione-ivas",
            edit:'/tipo-liquidazione-ivas/edit/:id',
            create: "/tipo-liquidazione-ivas/create",
            meta: {label: "Tipo Liquidazione Iva", icon: <SafetyOutlined/>,  parent: 'Anagrafiche'},
        },
    ];

    return (
        <LiveEventSocketCtxProvider>
            <BrowserRouter>
                <RefineKbarProvider>
                    <ColorModeContextProvider>
                        <ConfigProvider theme={{
                            ...RefineThemes.Green,
                            token: {
                                colorPrimary: "#91b76d",
                            },
                            algorithm: [theme.compactAlgorithm]
                        }}>
                            <TotaleNotificheProvider>
                            <Refine
                                authProvider={authProvider}
                                dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
                                notificationProvider={notificationProvider}
                                accessControlProvider={{
                                    can: async ({action, params, resource}) => {
                                        const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
                                        const role = decoded.role;
                                        const enforcer = await newEnforcer(model, adapter);
                                        const can = await enforcer.enforce(
                                            role,
                                            resource,
                                            action,
                                        );
                                        return Promise.resolve({can});
                                    },
                                }}
                                routerProvider={routerBindings}
                                options={{
                                    syncWithLocation: true,
                                    warnWhenUnsavedChanges: true,
                                }}
                                resources={myResource}
                            >
                                <Routes>
                                    <Route
                                        element={
                                            <Authenticated
                                                fallback={<CatchAllNavigate to="/login"/>}
                                                loading={<div style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}><Spin/></div>}
                                            >
                                                <ThemedLayoutV2
                                                    Header={() => <Header sticky={true}/>}
                                                    Sider={() => {
                                                        return (<CustomSider/>)
                                                    }}
                                                    OffLayoutArea={OffLayoutArea}
                                                >
                                                    <Outlet/>
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route index element={<NavigateToResource resource="dashboard"/>}/>

                                        <Route path="/dashboard">
                                            <Route index element={<DashboardPage/>}/>
                                        </Route>
                                        <Route path="/analisis">
                                            <Route index element={<AnalisiList/>}/>
                                            <Route
                                                path="create"
                                                element={<AnalisiCreate/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<AnalisiEdit/>}
                                            />
                                            <Route
                                                path="show/:id"
                                                element={<AnalisiShow/>}
                                            />
                                        </Route>

                                        <Route path="/agendas">
                                            <Route index element={<AgendaPage/>}/>
                                        </Route>
                                        <Route path="/clientes">
                                            <Route index element={<ClientiList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateCliente/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<ClienteEdit/>}
                                            />
                                        </Route>
                                        <Route path="/registraziones">
                                            <Route index element={<RegistrazioniList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateRegistrazioni/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<RegistrazioniEdit/>}
                                            />
                                        </Route>
                                        <Route path="/dati-aziendas">
                                            <Route index element={<DatiAziendaList/>}/>
                                            <Route
                                                path="edit/:id"
                                                element={<DatiAziendaEdit/>}
                                            />
                                        </Route>
                                        <Route path="/users">
                                            <Route index element={<UsersList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateUser/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<UserEdit/>}
                                            />
                                        </Route>
                                        <Route path="/listino-bases">
                                            <Route index element={<ListinoBaseList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateListinoBase/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<ListinoBaseEdit/>}
                                            />
                                        </Route>
                                        <Route path="/naturas">
                                            <Route index element={<NaturaList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateNatura/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<NaturaEdit/>}
                                            />
                                        </Route>
                                        <Route path="/cassa-professionales">
                                            <Route index element={<CassaProfessionaleList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateCassaProfessionale/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<CassaProfessionaleEdit/>}
                                            />
                                        </Route>
                                        <Route path="/posizione-previdenziales">
                                            <Route index element={<PosizionePrevidenzialeList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreatePosizionePrevidenziale/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<PosizionePrevidenzialeEdit/>}
                                            />
                                        </Route>
                                        <Route path="/stato-registraziones">
                                            <Route index element={<StatoRegistrazioneList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateStatoRegistrazione/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<StatoRegistrazioneEdit/>}
                                            />
                                        </Route>
                                        <Route path="/tipo-contabilitas">
                                            <Route index element={<TipoContabilitaList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateTipoContabilita/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<TipoContabilitaEdit/>}
                                            />
                                        </Route>
                                        <Route path="/tipo-dichiaraziones">
                                            <Route index element={<TipoDichiarazioneList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateTipoDichiarazione/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<TipoDichiarazioneEdit/>}
                                            />
                                        </Route>
                                        <Route path="/tipo-liquidazione-ivas">
                                            <Route index element={<TipoLiquidazioneIvaList/>}/>
                                            <Route
                                                path="create"
                                                element={<CreateTipoLiquidazioneIva/>}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<TipoLiquidazioneIvaEdit/>}
                                            />
                                        </Route>
                                    </Route>
                                    <Route
                                        element={
                                            <Authenticated fallback={<Outlet/>}>
                                                <NavigateToResource resource="dashboard"/>
                                            </Authenticated>
                                        }
                                    >
                                        <Route
                                            path="/login"
                                            element={<AuthPage
                                                contentProps={{
                                                    headStyle: {
                                                        color: "#91b76d",
                                                        fontSize: 20,
                                                        textAlign: "center"
                                                    },
                                                }}
                                            />}
                                        />
                                        <Route
                                            path="/forgot-password"
                                            element={<ForgotPasswordPage/>}
                                        />
                                        <Route
                                            path="/register"
                                            element={<RegisterPage/>}
                                        />
                                        <Route
                                            path="/update-password"
                                            element={<UpdatePasswordPage/>}
                                        />
                                    </Route>
                                    <Route
                                        element={
                                            <Authenticated>
                                                <ThemedLayoutV2>
                                                    <Outlet/>
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route path="*" element={<ErrorComponent/>}/>
                                    </Route>
                                </Routes>
                                <UnsavedChangesNotifier/>
                            </Refine>
                            </TotaleNotificheProvider>
                        </ConfigProvider>
                    </ColorModeContextProvider>
                </RefineKbarProvider>
            </BrowserRouter>
        </LiveEventSocketCtxProvider>
    );
}

export default App;
