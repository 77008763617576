import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act
[policy_definition]
p = sub, obj, act, eft
[role_definition]
g = _, _
[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))
[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, Amministratore, dashboard, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, analisis, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, agendas, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, clientes, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, registraziones, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, dati-aziendas, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, users, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, listino-bases, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, cassa-professionales, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, posizione-previdenziales, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, stato-registraziones, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, tipo-contabilitas, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, tipo-dichiaraziones, (list)|(edit)|(create)|(delete)|(show)
p, Amministratore, tipo-liquidazione-ivas, (list)|(edit)|(create)|(delete)|(show)
`);
