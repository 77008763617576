



import {ICassaProfessionale} from "interfaces";
import React from "react";
import {IResourceComponentsProps} from "@refinedev/core";
import {Create, useForm} from "@refinedev/antd";
import {Form, Input} from "antd";


export const CreateCassaProfessionale: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<ICassaProfessionale>({});

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    label="Descrizione"
                    name="descCassaProfessionale"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Create>
    );
};
