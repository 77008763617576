import React from "react";


import {IDatiAzienda} from "interfaces";
import {IResourceComponentsProps} from "@refinedev/core";
import {Edit, useForm} from "@refinedev/antd";
import {Col, Form, Input, Row} from "antd";

export const DatiAziendaEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IDatiAzienda>({});

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Ragione Sociale"
                            name="ragioneSociale"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Costo Minimo Orario"
                            name="costoMinimoOra"
                        >
                            <Input type="number"/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Costo Minimo Minuto"
                            name="costoMinimoMinuto"
                        >
                            <Input type="number"/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Nr. gg Preallarme Scadenza"
                            name="nGGPreallarmeScadenza"
                        >
                            <Input type="number"/>
                        </Form.Item>

                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Nr. giorni Agg. Cruscotto"
                            name="nMinutiAggiornamentoCruscotto"
                        >
                            <Input type="number"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Anno Fascicolo"
                            name="annoFascicolo"
                        >
                            <Input type="number"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Mese Fascicolo"
                            name="meseFascicolo"
                        >
                            <Input type="number"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Numero Fascicolo"
                            name="numeroFascicolo"
                        >
                            <Input type="number"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
