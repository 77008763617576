import {IListinoBase, INatura, ITipoContabilita} from "interfaces";
import React from "react";
import {IResourceComponentsProps} from "@refinedev/core";
import {Create, useForm, useSelect} from "@refinedev/antd";
import {Form, Input, InputNumber, Select} from "antd";


export const CreateListinoBase: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<IListinoBase>({

    });

    const {selectProps: naturaSelectProps} = useSelect<INatura>({
        resource: "naturas",
        optionLabel: "descNatura",
        optionValue: "id",
    });

    const {selectProps: tipoContabilitaSelectProps} = useSelect<ITipoContabilita>({
        resource: "tipo-contabilitas",
        optionLabel: "descTipoContabilita",
        optionValue: "id",
    });

    const onChange = (value: number | string) => {
        console.log('changed', value);
    };

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    label="Descrizione Listino"
                    name="descListinoBase"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Descrizione Attività"
                    name="descAttivitaFoglia"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Tipo Contabilità"
                    name={["rifTipoContabilita", "id"]}>
                    <Select
                        {...tipoContabilitaSelectProps}
                    />
                </Form.Item>
                <Form.Item
                    label="Natura"
                    name={["rifNatura", "id"]}>
                    <Select
                        {...naturaSelectProps}
                    />
                </Form.Item>
                <Form.Item
                    label="Con Paghe"
                    name="isConPaghe">
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}

                    />
                </Form.Item>
                <Form.Item
                    label="Contabilita Esterna"
                    name="isContabilitaEsterna">
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
                <Form.Item
                    label="Contabilita Interna"
                    name="isContabilitaInterna">
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
                <Form.Item
                    label="Privato"
                    name="isPrivato">
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
                <Form.Item
                    label="Gestione Costo/Ora"
                    name="isCostoOra">
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
                <Form.Item
                    label="Costo Fisso"
                    name="isCostoFisso">
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
                <Form.Item
                    label="Costo Orario"
                    name="euroOraListino"
                >
                    <InputNumber
                        addonBefore="€"
                        defaultValue={0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                </Form.Item>
                <Form.Item
                    label="Costo al miuto"
                    name="euroMinutoListino"
                >
                    <InputNumber
                        addonBefore="€"
                        defaultValue={0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                </Form.Item>
                <Form.Item
                    label="Euro Corpo Listino"
                    name="euroCorpoListino"
                >
                    <InputNumber
                        addonBefore="€"
                        defaultValue={0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                </Form.Item>
            </Form>
        </Create>
    );
};
