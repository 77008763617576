import {
    IResourceComponentsProps,
    HttpError,
    useParsed, useShow, useCustom, useCustomMutation, useExport,
} from "@refinedev/core";
import {Create, ExportButton, Show, useForm, useTable,} from "@refinedev/antd";
import {Button, DatePicker, Form, Input, Select, Switch, Table,} from "antd";
import {IAnalisi, IAnalisiFilter, ICliente} from "../../interfaces";
import React, {useEffect, useRef} from "react";
import dayjs from "dayjs";
import dayLocaleData from 'dayjs/plugin/localeData';
import 'dayjs/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import {Checkbox} from 'antd';
import type {CheckboxValueType} from 'antd/es/checkbox/Group';
import TextArea from "antd/lib/input/TextArea";
import {API_URL} from "../../constants";
import CsvDownloader from 'react-csv-downloader';
import {ExportOutlined} from "@ant-design/icons";


export const AnalisiShow: React.FC<IResourceComponentsProps> = () => {

    const {queryResult, showId} = useShow<IAnalisi>({
        resource: "analisis"
    });
    const dataResult = useCustomMutation();
    const result: any = useRef([]);
    const column: any = useRef([]);
    // console.log(queryResult.data?.data.sqlQuery)
    useEffect(() => {
        dataResult.mutateAsync({
            url: API_URL + "/api/runQuery",
            method: "post",
            values: {
                txtSql: queryResult.data?.data.txtSql,
                isExcel: true
            }
        }).then((r: any) => {
            console.log(r);
            if (r.data && r.data.dati && r.data.dati.length > 0) {
                Object.keys(r.data.dati[0]).map((c: any) => {
                    console.log(c)
                    column.current.push({
                        dataIndex: c,
                        index: c,
                        title: c,
                    })
                })
                result.current = r.data.dati;
            }
        })
    }, [queryResult.isSuccess]);

    // console.log(column.current)

    return (
        <>
            {result.current && result.current.length > 0 &&
                <Show
                    breadcrumb={false}
                    headerButtons={
                        <>
                            <CsvDownloader filename={queryResult.data?.data.nomeAnalisi ?? 'ExportAnalisi'}
                                           datas={result.current}>
                                <Button icon={<ExportOutlined/>}>Export</Button>
                            </CsvDownloader>
                        </>
                    }>
                    <Table size={"small"} dataSource={result.current} columns={column.current}>
                    </Table>
                </Show>
            }
        </>
    )
}
