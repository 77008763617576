import type {RefineThemedLayoutV2HeaderProps} from "@refinedev/antd";
import {useGetIdentity, useList, useLogout,} from "@refinedev/core";
import {
    AutoComplete,
    Badge, Button, Col, Divider, Drawer, Dropdown, Form, Input,
    Layout as AntdLayout, MenuProps, Modal, Row,
    Space,
    theme, Typography,
} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {ColorModeContext} from "../../contexts/color-mode";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {
    BellTwoTone, DownOutlined,
    LogoutOutlined, SearchOutlined, UnlockOutlined, UnlockTwoTone,
} from "@ant-design/icons";
import {ICliente, IOptions, IResetPassword, IUser} from "../../interfaces";
import {useModalForm} from "@refinedev/antd";
import {Link} from "react-router-dom";
const {useToken} = theme;
const {confirm} = Modal;
const {Text} = Typography;

const renderTitle = (title: string) => {
    return (
        <Text strong style={{fontSize: "16px"}}>
            {title}
        </Text>
    );
};

const renderItem = (title: string, resource: string, id: number) => {
    return {
        value: title,
        label: (
            <Link to={`/${resource}/edit/${id}`}>
                <Text>{title}</Text>
            </Link>
        ),
    };
};
export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
                                                                      isSticky,
                                                                  }) => {
    const {token} = useToken();
    const {data: user} = useGetIdentity<IUser>();
    const {mode, setMode} = useContext(ColorModeContext);
    const screens = useBreakpoint();
    const {mutate: mutateLogout} = useLogout();
    const [value, setValue] = useState<string>("");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [options, setOptions] = useState<IOptions[]>([]);

    const {refetch: refetchClienti} = useList<ICliente>({
        resource: "clientes",
        config: {
            filters: [{field: "descCliente", operator: "contains", value}],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const postOptionGroup = data.data.map((item) =>
                    renderItem(item.descCliente, "clientes", item.id),
                );
                if (postOptionGroup.length > 0) {
                    setOptions([
                        {
                            label: renderTitle("Clienti"),
                            options: postOptionGroup,
                        },
                    ]);
                }
            },
        },
    });

    useEffect(() => {
        setOptions([]);
        refetchClienti();
    }, [value]);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onClose = () => {
        setOpenDrawer(false);
    };

    const showLogoutConfirm = () => {
        confirm({
            title: 'Attenzione',
            icon: <LogoutOutlined/>,
            content: 'Sei sicuro di voler effettuare il logout?',
            okText: 'SI',
            okType: 'dashed',
            cancelText: 'NO',
            type: 'confirm',
            onOk() {
                console.log('OK');
                mutateLogout();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const userMenuItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={() => {
                    cambioPasswordModalShow(user?.id);
                }}>
                    Cambia Password
                </a>
            ),
            icon: <UnlockOutlined/>,
        },
        {
            key: '2',
            label: (
                <a onClick={showLogoutConfirm}>
                    Logout
                </a>
            ),
            icon: <LogoutOutlined/>,
        }
    ];

    const {
        formProps: cambioPasswordForm,
        modalProps: cambioPasswordModalProps,
        show: cambioPasswordModalShow
    } = useModalForm<IResetPassword>({
        resource: 'users',
        action: 'edit',
        successNotification: (data, values, resource) => {
            return {
                message: `Ricordati che la prossima volta che effettui il LOGIN dovrai utilizzare la nuova password`,
                description: "La password è stata aggiornata",
                type: "success",
            };
        },
        errorNotification: (data, values, resource) => {
            return {
                message: `Qualcosa è andato storto. Contatta il supporto tecnico`,
                description: "Errore",
                type: "error",
            };
        },
    });

    return (
        <>
            <Modal
                {...cambioPasswordModalProps}
                width={400}
                title={<div style={{paddingBottom: 10}}>Cambio Password</div>}
                closable={false}
                okText={'Salva'}
                cancelText={'Annulla'}
            >
                <Form {...cambioPasswordForm}
                      layout="vertical"
                      style={{width: '100%'}}
                >
                    <Form.Item
                        label={'Nuova Password'}
                        name={["password"]}
                        hasFeedback
                        rules={[
                            {required: true, message: 'Inserisci la nuova password'}
                        ]}
                    >
                        <Input.Password
                            minLength={6} prefix={<UnlockTwoTone/>}></Input.Password>
                    </Form.Item>
                    <Form.Item
                        label={'Conferma Password'}
                        name={["conferma"]}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Conferma la password'
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Le password non corrispondono'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            minLength={6} prefix={<UnlockTwoTone/>}></Input.Password>
                    </Form.Item>
                </Form>
            </Modal>
            <AntdLayout.Header style={{
                backgroundColor: token.colorBgElevated,
                padding: "0 24px",
                position: "sticky",
                top: 0,
                zIndex: 999,
            }}>
                <Row
                    align="middle"
                    style={{
                        justifyContent: screens.sm ? "space-between" : "end",
                    }}
                >
                    <Col xs={0} sm={12}>
                        <AutoComplete
                            style={{width: "100%", maxWidth: "550px"}}
                            filterOption={false}
                            options={options}
                            onSearch={(value: string) => setValue(value)}
                        >
                            <Input
                                size="large"
                                placeholder="Cerca Clienti, Registrazioni"
                                suffix={<SearchOutlined/>}
                            />
                        </AutoComplete>
                    </Col>

                    <Col>
                        <Space size="middle" align="center">
                            <Dropdown menu={{items: userMenuItems}}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        {user?.email}
                                        <DownOutlined/>
                                    </Space>
                                </a>
                            </Dropdown>
                            <Divider type={"vertical"} style={{color: '#91b76d', fontSize: 20}}></Divider>
                            <Badge color={'#91b76d'} count={5}>
                                <Button
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    type="default"
                                    icon={<BellTwoTone/>}
                                    onClick={() => {
                                        showDrawer();
                                    }}
                                />
                            </Badge>
                            {/*<Divider type={"vertical"} style={{color: '#91b76d', fontSize: 20}}></Divider>*/}
                            {/*<Button*/}
                            {/*    style={{*/}
                            {/*        display: "flex",*/}
                            {/*        alignItems: "center",*/}
                            {/*        justifyContent: "center",*/}
                            {/*    }}*/}
                            {/*    type="default"*/}
                            {/*    icon={mode === "light" ? <IconMoon/> : <IconSun/>}*/}
                            {/*    onClick={() => {*/}
                            {/*        setMode(mode === "light" ? "dark" : "light");*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </Space>
                    </Col>

                </Row>
            </AntdLayout.Header>
            <Drawer title="Notifiche" placement="right" onClose={onClose} open={openDrawer}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Drawer>
        </>
    );
};
