import {createContext, useContext} from "react";

export interface LiveEventSocketCtxState {
    event: "database" | "notifica" | "messaggio" | "open" | undefined;
    data: any;
}
// export interface LiveEventDataDatabase {
//     action: string;
//     tableName: string;
//     uid: string;
//     data: any;
// }
//
// export interface LiveEventDataMessage {
//     title: string;
//     message: string;
//     data: any;
// }

export const LiveEventSocketCtx = createContext<LiveEventSocketCtxState>({ event: undefined, data: null});

export const useLiveEventSocketCtx = () => useContext(LiveEventSocketCtx);
