import {Edit, SaveButton, useForm, useSelect} from "@refinedev/antd";
import {Button, Checkbox, Col, Form, Input, InputNumber, Modal, QRCode, Row, Select, Space, Switch} from "antd";
import {IAnalisi} from "../../interfaces";
import {HttpError, IResourceComponentsProps} from "@refinedev/core";
import React, {useState} from "react";

import PizZipUtils from 'pizzip/utils/index.js';
import TextArea from "antd/lib/input/TextArea";

function loadFile(url: any, callback: any) {
    PizZipUtils.getBinaryContent(url, callback);
}

export const AnalisiEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult} = useForm<
        IAnalisi,
        HttpError
    >({
        // metaData: {populate: "product"},
    });

    const info = () => {
        console.log(formProps?.initialValues?.id);
        Modal.info({
            title: 'Codice Cliente',
            content: (
                <div style={{textAlign: 'center', width: '100%', padding: 45}}>
                    <QRCode bordered={false} style={{textAlign: 'center', width: '100%'}} size={220}
                            value={formProps?.initialValues?.id}/>
                </div>
            ),
            // onOk() {},
        });
    };

    // const MyDocument = () => (
    //     <Document>
    //         <Page size="A4" style={styles.page}>
    //             <View style={styles.section}>
    //                 <Text>Section #1</Text>
    //             </View>
    //             <View style={styles.section}>
    //                 <Text>Section #2</Text>
    //             </View>
    //         </Page>
    //     </Document>
    // );
    //
    // const styles = StyleSheet.create({
    //     page: {
    //         flexDirection: 'row',
    //         backgroundColor: '#E4E4E4'
    //     },
    //     section: {
    //         margin: 10,
    //         padding: 10,
    //         flexGrow: 1
    //     }
    // });
    //
    // const generateDocument = (dati?: any) => {
    //     loadFile(
    //         '/template/stampa_template.docx',
    //         function (error: any, content: any) {
    //             if (error) {
    //                 throw error;
    //             }
    //             const zip = new PizZip(content);
    //             const doc = new Docxtemplater(zip, {
    //                 paragraphLoop: true,
    //                 linebreaks: true,
    //             });
    //             doc.setData({
    //                 ragSociale: queryResult?.data?.data.ragioneSociale,
    //                 indirizzo: queryResult?.data?.data.indirizzo,
    //                 cap: queryResult?.data?.data.cap,
    //                 citta: queryResult?.data?.data.citta,
    //                 provincia: queryResult?.data?.data.provincia,
    //                 nazione: queryResult?.data?.data.nazione,
    //                 iva: queryResult?.data?.data.pIva,
    //                 sdi: queryResult?.data?.data.codiceSdi,
    //                 baan: queryResult?.data?.data.codiceBaan,
    //                 referente: queryResult?.data?.data.referente,
    //                 mail: queryResult?.data?.data.contattoFax,
    //                 tel: queryResult?.data?.data.contattoTel,
    //             });
    //             try {
    //                 doc.render();
    //             } catch (error) {
    //                 throw error;
    //             }
    //             let out = doc.getZip().generate({
    //                 type: 'blob',
    //                 mimeType:
    //                     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //             }); //Output the document using Data-URI
    //             saveAs(out, 'cliente.docx');
    //         }
    //     );
    // };

    // var docxConverter = require('docx-pdf');
    //
    // docxConverter('./cliente.docx', './cliente.pdf', function (err:any, result:any) {
    //     if (err) {
    //         console.log(err);
    //     }
    //     console.log('result' + result);
    // });

    const options = [
        { label: 'SI', value: 'true' },
        { label: 'NO', value: 'false' },
    ];

    return (
        <Edit saveButtonProps={saveButtonProps}

              title="Modifica Analisi"
              breadcrumb={false}
              canDelete={true}
              headerButtons={
                  <>
                      <Button onClick={info}>
                          QR code
                      </Button>
                  </>
              }>
            <Form
                {...formProps}
                labelCol={{span: 4}}
                layout="horizontal"
                initialValues={{
                    isActive: true,
                    ...formProps.initialValues,
                }}
            >
                <Form.Item
                    label="Nome Analisi"
                    name={["nomeAnalisi"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Query"
                    name={["txtSql"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item
                    label="Excel"
                    name={["isExcel"]}
                    valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>
                <Form.Item
                    label="KPI"
                    name={["isKPI"]}
                    valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>
            </Form>
        </Edit>
    );
};
