import React, {useEffect} from "react";


import {ICliente, IUser} from "interfaces";
import {IResourceComponentsProps, useCustom, useTranslate} from "@refinedev/core";
import {DeleteButton, Edit, SaveButton, useForm, useSelect} from "@refinedev/antd";
import {Button, Col, Form, Input, InputNumber, Row, Select, Switch} from "antd";
import {API_URL} from "../../constants";
import {UnlockOutlined} from "@ant-design/icons";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        formProps, saveButtonProps,
        onFinish: save,
        queryResult: queryResultUser,
        formLoading
    } = useForm<IUser>({
        meta: {
            populate: '*',
        },
    });

    const {data: queryResultRole} = useCustom({
        url: `${API_URL}/api/users-permissions/roles`,
        method: 'get',
        meta: {
            pageSize: 9999,
        },
    });

    const optionsRole = queryResultRole?.data['roles'].map((x: any) => {
        return ({
            label: x.name,
            value: x.id
        })
    });

    const {selectProps: selectPropsClienti, queryResult: queryResultClienti} = useSelect<ICliente>({
        resource: "clientes",
        optionLabel: "descCliente",
        optionValue: "id",
        pagination: {mode: 'off'},
    });

    useEffect(() => {
        // console.log(formProps.form?.getFieldValue('role'));
        const clientiSelezionati: any = [];
        queryResultUser?.data?.data?.rifCliente?.map((cliente: ICliente) => {
                clientiSelezionati.push({label: cliente.descCliente, value: cliente.id});
            }
        );
        formProps.form?.setFields([{name: ["rifCliente"], value: clientiSelezionati}]);
        // console.log('DESTINAZIONI DEL FORM ', formProps.form?.getFieldValue('rifClienti'));

        // setRuoloSelezionato(queryResultUser?.data?.data?.role.id);
    }, [formLoading])


    return (
        <Edit saveButtonProps={saveButtonProps}
              title={translate('pages.users.titleEdit', 'Edit User')}
              headerButtons={() => (
                  <>
                      <Button onClick={() => {
                          // cambioPasswordModalShow(formProps.form?.getFieldValue('id'))
                      }}
                              icon={<UnlockOutlined/>}>Reset Password</Button>
                      <DeleteButton/>
                      <SaveButton {...saveButtonProps}/>
                  </>
              )}
              isLoading={formLoading}
              footerButtons={<></>}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(valueForm: any) => {
                    valueForm.role = valueForm.role.id;
                    console.log('MAP CLIENTI PRIMA', valueForm.rifCliente);
                    valueForm.rifCliente = valueForm.rifCliente.map((x: any) => {
                        console.log('MAP CLIENTI', x);
                        return {id: x.value};
                    });
                    console.log('MAP CLIENTI DOPO', valueForm.rifCliente);
                    save(valueForm).then(r => {
                    });
                }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Ruolo"
                            name={['role', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select options={optionsRole}
                                    onChange={(value) => {
                                        console.log(value);
                                        // setRuoloSelezionato(value);
                                    }}>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={translate('pages.users.stato', 'Bloccato')}
                            name="blocked"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label={translate('pages.users.nome', 'Name')}
                            name="nome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label={translate('pages.users.cognome', 'Cognome')}
                            name="cognome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label={translate('pages.users.username', 'User Name')}
                            name="username"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label={translate('pages.users.email', 'Email')}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label="Costo Orario Effettivo"
                            name="costoOraEffettivo"
                            rules={[
                                {
                                    required: true,
                                    type: "number"
                                },
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label="Costo Minuto Effettivo"
                            name="costoMinutoEffettivo"
                            rules={[
                                {
                                    required: true,
                                    type: "number"
                                },
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Telegram ON"
                            name="isTelegramReminderOn"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean"
                                },
                            ]}
                        >
                            <Switch/>

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="ID Telegram"
                            name="telegramId"
                            rules={[
                                {
                                    type: "string"
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Email ON"
                            name="isEmailReminderOn"
                            valuePropName="checked"
                            rules={[
                                {
                                    type: "boolean"
                                },
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Select
                            mode={'multiple'}
                            style={{width: '100%'}}
                            {...selectPropsClienti}
                            onChange={(values) => {
                                const temp: any = values ?? [];
                                // console.log('CLIENTI ON CHANGE', temp);
                                const clientiSelezionati: any = [];
                                queryResultClienti.data?.data.filter((x) => temp.includes(x.id)).map((cliente) => {
                                        clientiSelezionati.push({label: cliente.descCliente, value: cliente.id});
                                    }
                                );
                                // console.log('CLIENTI ON clientiSelezionati', clientiSelezionati);
                                formProps.form?.setFields([{name: ["rifCliente"], value: clientiSelezionati}]);
                            }}
                        >
                        </Select>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
