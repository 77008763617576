import { IStatoRegistrazione} from "interfaces";
import React from "react";
import {StatoRegistrazioneList} from "./list";
import {IResourceComponentsProps} from "@refinedev/core";
import {Create, useForm} from "@refinedev/antd";
import {Form, Input, Select} from "antd";


export const CreateStatoRegistrazione: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<IStatoRegistrazione>({});

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    // wrapperCol={{span: 14}}
                    label="Descrizione"
                    name="descStatoRegistrazione"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Chiudi Precedente"
                    name="isChiudiPrecedente"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="Fatturabile"
                    name="isFatturabile"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="Fatturato"
                    name="isFatturato"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                    />
                </Form.Item>
            </Form>
        </Create>
    );
};
