import React, {useEffect, useState} from 'react';
import {
    ITreeMenu,
    CanAccess,
    useIsExistAuthentication,
    useTranslate,
    useLogout,
    useMenu,
    useWarnAboutChange, useGetIdentity,
} from '@refinedev/core';
import {Link} from 'react-router-dom';
import {Sider} from '@refinedev/antd';
import {Layout as AntdLayout, Menu, Grid, theme, Button} from 'antd';
import {
    UnorderedListOutlined,
    RightOutlined,
    LeftOutlined, DashboardTwoTone, IssuesCloseOutlined,
} from '@ant-design/icons';
import {antLayoutSider, antLayoutSiderMobile} from './styles';
import {Title} from '../title';
import {IUser} from "../../interfaces";

const {useToken} = theme;
export const CustomSider: typeof Sider = ({render}) => {
    const {token} = useToken();
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const isExistAuthentication = useIsExistAuthentication();
    // const {warnWhen, setWarnWhen} = useWarnAboutChange();
    // const translate = useTranslate();
    const {menuItems, selectedKey, defaultOpenKeys} = useMenu();
    const {SubMenu} = Menu;
    const breakpoint = Grid.useBreakpoint();
    // const {data: userData} = useGetIdentity<IUser>();

    // console.log(menuItems)

    const isMobile =
        typeof breakpoint.lg === 'undefined' ? false : !breakpoint.lg;

    // useEffect(() => {
    //     menuItems.push({
    //         name: "variazioni-di-statoes",
    //         show: "/variazioni-di-statoes/show/1",
    //         meta: {label: "Mario", icon: <IssuesCloseOutlined/>},
    //     });
    // }, [menuItems]);

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        // if(tree.findIndex((value, index) => value.name === 'variazioni-di-statoes') === -1){
        //
        // }
        return tree.map((item: ITreeMenu) => {
            const {name, children, meta, key, list} = item;
            const icon = meta?.icon;
            const label = meta?.label ?? name;
            const parent = meta?.parent;
            const route =
                typeof list === 'string'
                    ? list
                    : typeof list !== 'function'
                        ? list?.path
                        : key;

            if (children.length > 0) {
                return (
                    <SubMenu
                        key={route}
                        icon={icon ?? <UnorderedListOutlined/>}
                        title={label}
                    >
                        {renderTreeView(children, selectedKey)}
                    </SubMenu>
                );
            }
            const isSelected = route === selectedKey;
            const isRoute = !(parent !== undefined && children.length === 0);
            return (
                <CanAccess
                    key={route}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{resource: item}}
                >
                    <Menu.Item
                        key={route}
                        style={{
                            textTransform: 'capitalize',
                        }}
                        icon={icon ?? (isRoute && <UnorderedListOutlined/>)}
                    >
                        {route ? <Link to={route || '/'}>{label}</Link> : label}
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow"/>
                        )}
                    </Menu.Item>
                </CanAccess>
            );
        });
    };


    const logout = isExistAuthentication && (
        <></>
    );

    const items = renderTreeView(menuItems, selectedKey);

    const renderSider = () => {
        if (render) {
            return render({
                dashboard: null,
                items,
                logout,
                collapsed,
            });
        }
        return (
            <>
                {items}
                {logout}
            </>
        );
    };

    const siderStyle = isMobile ? antLayoutSiderMobile : antLayoutSider;

    return (
        <AntdLayout.Sider
            title={'SDF'}
            collapsible
            collapsedWidth={isMobile ? 0 : 80}
            collapsed={collapsed}
            breakpoint="lg"
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            style={{
                ...siderStyle,
                backgroundColor: token.colorBgContainer,
                borderRight: `1px solid ${token.colorBgElevated}`,
                position: "sticky",
                top: 0,
                // zIndex: 999999,
            }}
            trigger={
                !isMobile && (
                    <Button
                        key='collaps'
                        type="text"
                        style={{
                            borderRadius: 0,
                            height: '100%',
                            width: '100%',
                            backgroundColor: token.colorBgElevated,
                        }}
                    >
                        {collapsed ? (
                            <RightOutlined
                                style={{
                                    color: token.colorPrimary,
                                }}
                            />
                        ) : (
                            <LeftOutlined
                                style={{
                                    color: token.colorPrimary,
                                }}
                            />
                        )}
                    </Button>
                )
            }
        >

            <Title collapsed={collapsed}/>

            <Menu
                key={'jjj'}
                defaultOpenKeys={defaultOpenKeys}
                selectedKeys={[selectedKey]}
                mode="inline"
                style={{
                    marginTop: '8px',
                    border: 'none',
                }}
                onClick={() => {
                    if (!breakpoint.lg) {
                        setCollapsed(true);
                    }
                }}
            >
                {renderSider()}
            </Menu>
        </AntdLayout.Sider>
    );
};
