import React from "react";


import {IListinoBase, INatura, ITipoContabilita} from "interfaces";
import {IResourceComponentsProps} from "@refinedev/core";
import {Edit, useForm, useSelect} from "@refinedev/antd";
import {Col, Form, Input, InputNumber, Row, Select} from "antd";

export const ListinoBaseEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IListinoBase>({
        metaData: {populate: '*'}
    });

    const {selectProps: naturaSelectProps} = useSelect<INatura>({
        resource: "naturas",
        optionLabel: "descNatura",
        optionValue: "id",
    });

    const {selectProps: tipoContabilitaSelectProps} = useSelect<ITipoContabilita>({
        resource: "tipo-contabilitas",
        optionLabel: "descTipoContabilita",
        optionValue: "id",
    });


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    label="Descrizione Listino"
                    name="descListinoBase"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Descrizione Attività"
                    name="descAttivitaFoglia"
                >
                    <Input/>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Tipo Contabilità"
                            name={["rifTipoContabilita", "id"]}>
                            <Select
                                {...tipoContabilitaSelectProps}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Natura"
                            name={["rifNatura", "id"]}>
                            <Select
                                {...naturaSelectProps}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Con Paghe"
                            name="isConPaghe">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Contabilita Esterna"
                            name="isContabilitaEsterna">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Contabilita Interna"
                            name="isContabilitaInterna">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Privato"
                            name="isPrivato">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Gestione Costo/Ora"
                            name="isCostoOra">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Costo Fisso"
                            name="isCostoFisso">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label="Costo Orario"
                            name="euroOraListino">
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Costo al miuto"
                            name="euroMinutoListino"
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Euro Corpo Listino"
                            name="euroCorpoListino"
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
