import {ITipoContabilita} from "interfaces";
import React from "react";
import {IResourceComponentsProps} from "@refinedev/core";
import {Create, useForm} from "@refinedev/antd";
import {Form, Input} from "antd";


export const CreateTipoContabilita: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<ITipoContabilita>({});

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    // wrapperCol={{span: 14}}
                    label="Descrizione"
                    name="descTipoContabilita"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Create>
    );
};
