import React from "react";
import {IUser} from "../../interfaces";
import {IResourceComponentsProps, useExport, useTranslate} from "@refinedev/core";
import {BooleanField, CreateButton, DeleteButton, EditButton, ExportButton, List, useTable} from "@refinedev/antd";
import {Space, Table} from "antd";
import * as Icons from "@ant-design/icons";

export const UsersList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps} = useTable<IUser>({
        resource: "users",
        metaData: {populate: '*'}
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IUser>();

    return (
        <List
            title={translate('pages.users.title', 'Users')}
            headerButtons={
                <>
                    <CreateButton/>,
                    <ExportButton
                        onClick={triggerExport}
                        loading={exportLoading}
                    />
                </>}
        >
            <Table size="small" {...tableProps} rowKey="id"
                   onRow={(record) => {
                       return {
                           onClick: () => {
                               console.log("user", record);
                           },
                       };
                   }}
                   sticky={true}

                   pagination={{
                       ...tableProps.pagination,
                       showSizeChanger: true,
                   }}>
                <Table.Column dataIndex="id" key="id" title="ID"/>
                <Table.Column
                    dataIndex="nome"
                    key="nome"
                    title={translate('pages.users.nome', 'Name')}
                />
                <Table.Column
                    dataIndex="cognome"
                    key="cognome"
                    title={translate('pages.users.cognome', 'Surname')}
                />
                <Table.Column dataIndex="email" key="email" title={translate('pages.users.email', 'Email')}/>
                <Table.Column
                    dataIndex="confirmed"
                    title={translate('pages.users.stato', 'Stato')}
                    render={(value) => (
                        <BooleanField
                            value={value === true}
                            trueIcon={<Icons.CheckCircleOutlined/>}
                            falseIcon={<Icons.CloseCircleOutlined/>}
                        />
                    )}
                />
                <Table.Column<{ id: string }>
                    title={translate('pages.users.azioni', 'Action')}
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />

            </Table>
        </List>
    );
};
