import {RefineKbar} from "@refinedev/kbar";


export const OffLayoutArea: React.FC = () => {
    return  (
        <>
            <RefineKbar />
        </>
    );

};
