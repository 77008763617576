
import React from "react";
import { ITipoLiquidazioneIva} from "../../interfaces";
import {IResourceComponentsProps} from "@refinedev/core";
import {CloneButton, DeleteButton, EditButton, List, useTable} from "@refinedev/antd";
import {Space, Table} from "antd";

export const TipoLiquidazioneIvaList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps} = useTable<ITipoLiquidazioneIva>({
        resource: "tipo-liquidazione-ivas",

    });



    return (
        <List  title="Tipo Liquidazione">
            <Table size="small" {...tableProps} rowKey="id"
                   pagination={{
                       ...tableProps.pagination,
                       showSizeChanger: true,
                   }}>
                <Table.Column dataIndex="id" key="id" title="ID"/>
                <Table.Column
                    dataIndex="descTipoLiquidazioneIva"
                    key="descTipoLiquidazioneIva"
                    title="Descrizione"
                />
                <Table.Column<{ id: string }>
                    title="Azioni"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <CloneButton hideText size="small" recordItemId={record.id} />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
