import {
    ICassaProfessionale,
    INatura,
    IPosizionePrevidenziale,
    ITipoContabilita,
    ITipoDichiarazione, ITipoLiquidazioneIva,
    IUser
} from "interfaces";
import React, {useState} from "react";
import {API_URL, TOKEN_KEY} from "../../constants";
import {IResourceComponentsProps, useDelete} from "@refinedev/core";
import {Button, Col, Form, Input, Row, Select, Space, Table, Upload, UploadProps} from "antd";
import {Create, getValueFromEvent, useForm, useSelect} from "@refinedev/antd";
import * as Icons from "@ant-design/icons";

export const CreateCliente: React.FC<IResourceComponentsProps> = () => {
    const { Column } = Table;
    const {formProps, saveButtonProps} = useForm<IUser>({
        metaData: {populate: '*'},
    });

    const removeFile = useDelete();
    const [allegati, setAllegati] = useState<any>([]);

    const {selectProps: tipoContabilitaSelectProps} = useSelect<ITipoContabilita>({
        resource: "tipo-contabilitas",
        optionLabel: "descTipoContabilita",
        optionValue: "id",
    });

    const {selectProps: cassaProfessionaleSelectProps} = useSelect<ICassaProfessionale>({
        resource: "cassa-professionales",
        optionLabel: "descCassaProfessionale",
        optionValue: "id",
    });

    const {selectProps: naturaSelectProps} = useSelect<INatura>({
        resource: "naturas",
        optionLabel: "descNatura",
        optionValue: "id",
    });

    const {selectProps: posizionePrevidenzialeSelectProps} = useSelect<IPosizionePrevidenziale>({
        resource: "posizione-previdenziales",
        optionLabel: "descPosizionePrevidenziale",
        optionValue: "id",
    });

    const {selectProps: tipoDichiarazioneSelectProps} = useSelect<ITipoDichiarazione>({
        resource: "tipo-dichiaraziones",
        optionLabel: "descTipoDichiarazione",
        optionValue: "id",
    });

    const {selectProps: tipoLiquidazioneIvaSelectProps} = useSelect<ITipoLiquidazioneIva>({
        resource: "tipo-liquidazione-ivas",
        optionLabel: "descTipoLiquidazioneIva",
        optionValue: "id",
    });

    const {selectProps: usersSelectProps} = useSelect<IUser>({
        resource: "users",
        optionLabel: "username",
        optionValue: "id",
    });

    const uploadProps: UploadProps = {
        name: 'files',
        multiple: true,
        action: API_URL + 'api/upload',
        showUploadList: false,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
        onChange(info: any) {
            const {status} = info.file;
            if (status !== 'uploading') {
                // queryResult?.data?.data.allegatiCliente.push(info.file.response[0]);
                setAllegati([...allegati])
            }
            if (status === 'done') {
                console.log(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleVisibleChange = (newVisible: boolean, file: string) => {
        setAllegati(file);
        window.open(API_URL + file)
    };

    const handleRemove = (file: any) => {
        console.log(file);
        const i = 0;
        // const i: any = queryResult?.data?.data.allegatiCliente.findIndex(x => x.url === file);
        console.log("File: ", i);
        // removeFile.mutate({
        //     resource: "upload/files",
        //     id: Number(queryResult?.data?.data.allegatiCliente[i].id)
        // })
        if (removeFile.isSuccess) {
            // queryResult?.data?.data.allegatiCliente.splice(i, 1);
            // console.log(queryResult?.data?.data.allegatiCliente);
            setAllegati([...allegati])
        }

    };
    return (
        <Create saveButtonProps={saveButtonProps}
                headerButtons={({ defaultButtons }) => (
                    <>
                        {defaultButtons}
                        <Upload {...uploadProps} >
                            <Button icon={<Icons.FileAddOutlined />}>Carica Allegato</Button>
                        </Upload>

                    </>
                )}
        >
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col span={18}>
                        <Row gutter={16}>
                            <Col span={16}>
                                <Form.Item
                                    label="Ragione Sociale"
                                    name="descCliente"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Utente di default"
                                    name={["rifUser", "id"]}>
                                    <Select
                                        {...usersSelectProps}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={4}>
                                <Form.Item
                                    label="Familiare"
                                    name="isFamiliare">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Vies"
                                    name="isVies">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="CONAI"
                                    name="isCONAI">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Gestione Pec"
                                    name="isGestionePec">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Gestione Firma Digitale"
                                    name="isGestioneFirmaDigitale">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="Organo Di Controllo"
                                    name="isOrganoDiControllo">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Tipo Contabilità"
                                    name={["rifTipoContabilita", "id"]}>
                                    <Select
                                        {...tipoContabilitaSelectProps}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Natura"
                                    name={["rifNatura", "id"]}>
                                    <Select
                                        {...naturaSelectProps}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    label="Contabilita Interna"
                                    name="isContabilitaInterna">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Contabilita Esterna"
                                    name="isContabilitaEsterna">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Con Paghe"
                                    name="isConPaghe">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Privato"
                                    name="isPrivato">
                                    <Select
                                        options={[
                                            {
                                                label: "SI",
                                                value: true,
                                            },
                                            {
                                                label: "NO",
                                                value: false,
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Tipo Dichiarazione"
                                    name={["rifTipoDichiarazione", "id"]}>
                                    <Select
                                        {...tipoDichiarazioneSelectProps}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Tipo Liquidazione Iva"
                                    name={["rifTipoLiquidazioneIva", "id"]}>
                                    <Select
                                        {...tipoLiquidazioneIvaSelectProps}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Posizione Previdenziale"
                                    name={["rifPosizionePrevidenziale", "id"]}>
                                    <Select
                                        {...posizionePrevidenzialeSelectProps}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Cassa Professionale"
                                    name={["rifCassaProfessionale", "id"]}>
                                    <Select
                                        {...cassaProfessionaleSelectProps}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    label="Codice TS Paghe"
                                    name="codiceTSPaghe"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Codice TS Dichiarazioni"
                                    name="codiceTSDichiarazioni"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Codice TS 770"
                                    name="codiceTS770"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Codice TS Contabilita"
                                    name="codiceTSContabilita"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>

                        <Form.Item
                            name="allegatiCliente"
                            label="Allegati"
                            valuePropName="allegatiCliente"
                            getValueFromEvent={getValueFromEvent}
                            // noStyle
                        >
                            <Table dataSource={allegati} pagination={false}>
                                <Column title="File Name" dataIndex="name" key={Math.floor(Math.random() * (5 - 1 + 1)) + 1} />
                                <Column title="" dataIndex="url" render={(value) => (
                                    <>
                                        <Space>
                                            <Button key={'btn1'} type="primary" shape="circle"  icon={<Icons.EyeOutlined />} onClick={() => {handleVisibleChange(true, value)}}/>
                                            <Button key={'btn2'} type="primary" shape="circle" icon={<Icons.DeleteOutlined />} onClick={() => {handleRemove(value)}}/>
                                        </Space>
                                    </>
                                )}
                                />
                            </Table>
                            {/*<Upload*/}
                            {/*    {...uploadProps}*/}
                            {/*    defaultFileList={queryResult?.data?.data.allegatiCliente}*/}
                            {/*>*/}
                            {/*    <Button  icon={<Icons.UploadOutlined />}>Aggiungi Allegato</Button>*/}
                            {/*</Upload>*/}

                        </Form.Item>

                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
