import { CSSProperties } from "react";

// export const drawerButtonStyles: CSSProperties = {
//   borderTopLeftRadius: 0,
//   borderBottomLeftRadius: 0,
//   position: "fixed",
//   top: 64,
//   zIndex: 999,
// };
export const antLayoutSider: CSSProperties = {
  position: "relative",
};
export const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  // zIndex: 99,
};
