import {IResourceComponentsProps, useList} from "@refinedev/core";
import {DeleteButton, SaveButton, Show, useModalForm} from "@refinedev/antd";
import {
    Badge,
    Select,
    Calendar,
    Row,
    Col,
    Radio,
    Space,
    Button,
    Modal,
    Form, Input, DatePicker, theme, Switch,
} from "antd";
import dayjs from "dayjs";
import dayLocaleData from 'dayjs/plugin/localeData';
import 'dayjs/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import {IEvent} from "interfaces";
import "./styles.css";
import React, {useState} from "react";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";


dayjs.extend(dayLocaleData);
export const AgendaPage: React.FC<IResourceComponentsProps> = () => {

    dayjs.locale('it');
    const {data} = useList<IEvent>({
        resource: "agendas",
    });

    const [eventIdSelected, setEventIdSelected] = useState(0);
    const [calendarDateSelected, setCalendarDateSelected] = useState( dayjs());
    const dateCellRender = (value: dayjs.Dayjs) => {
        const listData = data?.data?.filter((p) =>
            dayjs(p.data).isSame(value, "day"),
        );
        return (
            <ul className="events">
                {listData?.map((item) => (
                    <li key={item.id}>
                        <div onClick={() => {
                            setEventIdSelected(item.id)
                        }}
                             onDoubleClick={() => {
                                 editModalShow(eventIdSelected);
                             }}
                        >
                            <Badge
                                style={{
                                    backgroundColor: item.id === eventIdSelected ? theme.defaultConfig.token.colorPrimary : theme.defaultConfig.token.colorBgBase,
                                    border: '1px solid lightgrey',
                                    borderRadius: 10,
                                    paddingLeft: 10
                                }}
                                // status={item.status as BadgeProps["status"]}
                                color={item.colore}
                                text={item.titolo}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
        // close: closeCreateModal
    } = useModalForm<IEvent>({
        action: "create"
    });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,

    } = useModalForm<IEvent>({
        action: "edit",
        warnWhenUnsavedChanges: true
    });

    const formFields = (
        <>
            <Form.Item
                label="Evento Aziendale"
                name="eventoAziendale"
                valuePropName="checked"

            >
                <Switch/>
            </Form.Item>
            <Form.Item
                label="Data Evento"
                name="data"
                rules={[
                    {
                        required: true
                    }
                ]}
                getValueProps={(value) => ({
                    value: value ? dayjs(value) : "",
                })}>
                <DatePicker locale={locale} showTime={true} style={{width: '100%'}}/>
            </Form.Item>
            <Form.Item
                label="Titolo"
                name="titolo"
                rules={[
                    {
                        required: true
                    }
                ]}>
                <Input/>
            </Form.Item>
            <Form.Item
                label="Descrizione"
                name="descrizione"
                rules={[
                    {
                        required: true
                    }
                ]}>
                <TextArea style={{height: 180}}/>
            </Form.Item>
            <Form.Item
                label="Stato"
                name="status"
                rules={[
                    {
                        required: true
                    }
                ]}>
                <Select
                    options={[
                        {
                            label: "Valido",
                            value: "warning"
                        },
                        {
                            label: "Normale",
                            value: "success"
                        },
                        {
                            label: "Importante",
                            value: "error"
                        }
                    ]}/>
            </Form.Item>
            <Form.Item
                label="Colore"
                name="colore"
                rules={[
                    {
                        required: true
                    }
                ]}>
                <Select
                    options={[
                        {
                            label: "Rosa",
                            value: "pink"
                        },
                        {
                            label: "Rosso",
                            value: "red"
                        },
                        {
                            label: "Giallo",
                            value: "yellow"
                        },
                        {
                            label: "Arancione",
                            value: "orange"
                        },
                        {
                            label: "Ciano",
                            value: "cyan"
                        },
                        {
                            label: "Verde",
                            value: "green"
                        },
                        {
                            label: "Blu",
                            value: "blue"
                        },
                        {
                            label: "Viola",
                            value: "purple"
                        },
                        {
                            label: "Geekblue",
                            value: "geekblue"
                        },
                        {
                            label: "magenta",
                            value: "magenta"
                        },
                        {
                            label: "Vulcano",
                            value: "volcano"
                        },
                        {
                            label: "Oro",
                            value: "gold"
                        },
                        {
                            label: "Lime",
                            value: "lime"
                        },
                    ]}/>
            </Form.Item>
        </>);

    return (
        <Show title={false} headerProps={{extra: null}}>
            <Calendar
                cellRender={dateCellRender}
                onSelect={(value) => {
                    setCalendarDateSelected(value);
                    createFormProps.form?.setFieldValue('data', dayjs())
                }}
                headerRender={({value, type, onChange, onTypeChange}) => {
                    const start = 0;
                    const end = 12;
                    const monthOptions = [];
                    let current = value.clone();
                    const localeData = value.localeData();
                    const months = [];
                    for (let i = 0; i < 12; i++) {
                        current = current.month(i);
                        months.push(localeData.months(current).toString().toUpperCase());
                    }

                    for (let i = start; i < end; i++) {
                        monthOptions.push(
                            <Select.Option key={i} value={i} className="month-item">
                                {months[i]}
                            </Select.Option>,
                        );
                    }

                    const year = value.year();
                    const month = value.month();
                    const options = [];
                    for (let i = year - 10; i < year + 10; i += 1) {
                        options.push(
                            <Select.Option key={i} value={i} className="year-item">
                                {i}
                            </Select.Option>,
                        );
                    }
                    return (
                        <div style={{
                            padding: 8,
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <Row gutter={8}>
                                <Col>
                                    <Radio.Group
                                        onChange={(e) => onTypeChange(e.target.value)}
                                        value={type}
                                    >
                                        <Radio.Button value="month">Mese</Radio.Button>
                                        <Radio.Button value="year">Anno</Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <Col>
                                    <Select
                                        style={{width: 100}}
                                        dropdownMatchSelectWidth={false}
                                        className="my-year-select"
                                        value={year}
                                        onChange={(newYear) => {
                                            const now = value.clone().year(newYear);
                                            onChange(now);
                                        }}
                                    >
                                        {options}
                                    </Select>
                                </Col>
                                <Col>
                                    <Select
                                        style={{width: 130}}
                                        dropdownMatchSelectWidth={false}
                                        value={month}
                                        onChange={(newMonth) => {
                                            const now = value.clone().month(newMonth);
                                            onChange(now);
                                        }}
                                    >
                                        {monthOptions}
                                    </Select>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Space wrap>
                                    <Button onClick={() => {
                                        editModalShow(eventIdSelected);
                                    }} type="dashed" hidden={eventIdSelected === 0} icon={<EditOutlined/>}>
                                        Modifica Evento
                                    </Button>
                                    <Button onClick={() => createModalShow()} type="primary" icon={<PlusOutlined/>}>
                                        Nuovo Evento
                                    </Button>
                                </Space>
                            </Row>
                        </div>
                    );
                }}
            />
            <Modal {...createModalProps} width={window.innerWidth / 2} title={'Nuovo Evento'}>
                <Form {...createFormProps}
                      labelCol={{span: 4}}
                      layout="horizontal"

                >
                    {formFields}
                </Form>
            </Modal>
            <Modal {...editModalProps} footer={
                <>
                    <DeleteButton recordItemId={editFormProps.form?.getFieldValue('id')}>Elimina</DeleteButton>
                    {/*<Button onClick={closeCreateModal}>Annulla</Button>*/}
                    <SaveButton >Salva</SaveButton>
                </>
            } title={'Modifica Evento'} width={window.innerWidth / 2}>
                <Form {...editFormProps}

                      labelCol={{span: 4}}
                      layout="horizontal"
                >
                    {formFields}
                </Form>
            </Modal>
        </Show>
    );
};
