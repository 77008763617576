import { AuthBindings } from "@refinedev/core";
import { AuthHelper } from "@refinedev/strapi-v4";
import { TOKEN_KEY, API_URL } from "./constants";
import axios from "axios";
export const axiosInstance = axios.create({});
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthBindings = {
    register: async ({email, password}) => {
        const randId = (Math.random() + 1).toString(36).substring(5)
        console.log(email,password, randId);

        const user = await axios
            .post(`${API_URL}/api/auth/local/register`, {
                username: `${email.split('@')[0]}-${randId}`,
                email: email,
                password: password,
            });
        if (!user) {
            return {
                success: false,
                error: {
                    message: 'Qualcosa è andato storto durante la registrazione. Riprova più tardi o contatta l\'assistenza.',
                    name:  'Errore',
                },
            };
        }
        return {
            success: true,
            redirectTo: "/login",
        };

    },
    login: async ({ email, password }) => {
        const loginResponse = await axios
            .post(`${API_URL}/api/auth/local`, {
                identifier: email,
                password: password,
            });
        if (loginResponse.status === 200) {
            localStorage.setItem(TOKEN_KEY, loginResponse.data.jwt);

            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${loginResponse.data.jwt}`;
            return {
                success: true,
                redirectTo:  "/",
            };
        }
        return {
            success: false,
            error: {
                message: "Login failed",
                name: "Invalid email or password",
            },
        };
    },
    logout: async () => {
        localStorage.removeItem(TOKEN_KEY);
        return {
            success: true,
            redirectTo: "/login",
        };
    },
    onError: async (error) => {
        console.error(error);
        return { error };
    },
    check: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            axiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${token}`;
            axiosInstance.defaults.headers.common.Authorization =  `Bearer ${token}`;
            // const headers = {"Authorization": `Bearer ${token}`};
            // axiosInstance.defaults.headers.common = {...headers};
            return {
                authenticated: true,
            };
        }

        return {
            authenticated: false,
            error: {
                message: "Check failed",
                name: "Token not found",
            },
            logout: true,
            redirectTo: "/login",
        };
    },
    getPermissions: async () => {
        return 'Admin'
    },
    getIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return null;
        }

        const { data, status } = await strapiAuthHelper.me(token);
        // console.log('LOGIN DATA',data);
        if (status === 200) {
            const { id, username, email } = data;
            return {
                id,
                name: username,
                email,
            };
        }

        return null;
    },
};
