import {
    IResourceComponentsProps,
    HttpError,
    useParsed,
} from "@refinedev/core";
import {Create, useForm,} from "@refinedev/antd";
import {DatePicker, Form, Input, Select, Switch,} from "antd";
import {IAnalisi} from "../../interfaces";
import React from "react";
import dayjs from "dayjs";
import dayLocaleData from 'dayjs/plugin/localeData';
import 'dayjs/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import TextArea from "antd/lib/input/TextArea";


export const AnalisiCreate: React.FC<IResourceComponentsProps> = () => {
    const {params} = useParsed<{ tenant: string }>();
    const {formProps, saveButtonProps} = useForm<
        IAnalisi,
        HttpError
    >();

    const options = [
        { label: 'SI', value: 'true' },
        { label: 'NO', value: 'false' },
    ];

    return (
        <Create saveButtonProps={saveButtonProps}
                title="Nuova Richiesta"
                breadcrumb={false}
        >
            <Form
                {...formProps}
                layout="horizontal"
                labelCol={{span: 4}}
                initialValues={{
                    isActive: true,
                }}
                onFinish={(values) => {
                    return formProps.onFinish?.({
                        ...values,
                        stores: [params!.tenant],
                    });
                }}
            >
                <Form.Item
                    label="Nome Analisi"
                    name={["nomeAnalisi"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Query"
                    name={["txtSql"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item
                    label="Excel"
                    name={["isExcel"]}
                    valuePropName="checked"
                    // rules={[
                    //     {
                    //         required: true,
                    //     },
                    // ]}
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="KPI"
                    name={["isKPI"]}
                    valuePropName="checked"
                    // rules={[
                    //     {
                    //         required: true,
                    //     },
                    // ]}
                >
                    <Switch/>
                </Form.Item>
            </Form>
        </Create>
    );
};
