import {
    HttpError,
    IResourceComponentsProps,
    useExport, useNavigation,

} from "@refinedev/core";

import {
    BooleanField,
    CreateButton, DeleteButton, EditButton,
    ExportButton,
    List, useTable
} from '@refinedev/antd';
import {Button, Form, Space, Table} from 'antd';
import React from 'react';
import {IAnalisi, IAnalisiFilter} from "../../interfaces";
import Search from "antd/es/input/Search";
import {CheckOutlined, CheckSquareFilled, CloseSquareFilled, PlaySquareFilled,} from "@ant-design/icons";


// const {Title} = Typography;
export const AnalisiList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps, searchFormProps} = useTable<IAnalisi, HttpError, IAnalisiFilter>({
        resource: 'analisis',
        onSearch: (values: any) => {
            // console.log(values);
            return [
                {
                    operator: "or",
                    value: [
                        {
                            field: "nomeAnalisi",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "txtSql",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "isExcel",
                            operator: "contains",
                            value: values.description
                        },
                        {
                            field: "isKPI",
                            operator: "contains",
                            value: values.description
                        },
                    ]
                },
            ];
        },
    });

    console.log(tableProps)
    const {triggerExport, isLoading: exportLoading} = useExport<IAnalisi>();

    const {edit, show} = useNavigation();


    return (

        <List title={
            // <Row>
            <Space style={{width: '100%'}} direction={"vertical"}>

                <Form style={{width: '100%'}}   {...searchFormProps} layout="inline">

                    <Form.Item name="description" style={{width: '100%'}}>
                        {/*<Input*/}
                        {/*    placeholder="Cerca nei clienti..."*/}
                        {/*    allowClear={true}/>*/}
                        <Search
                            style={{width: '100%'}}
                            placeholder="Cerca..."
                            allowClear={true}
                            enterButton
                            onSearch={searchFormProps.form?.submit}
                        />
                    </Form.Item>
                    {/*<Button onClick={searchFormProps.form?.submit}*/}
                    {/*        type={'primary'}>Cerca</Button>*/}
                </Form>
            </Space>
            // </Row>

        }
              breadcrumb={false}
              resource="analisis"
              createButtonProps={{size: "small"}}
              headerButtons={
                  <>
                      <ExportButton onClick={triggerExport} loading={exportLoading}/>
                      <CreateButton resource="analisis"/>
                  </>
              }>
            {/*<Title level={3}>Clienti</Title>*/}
            <Table {...tableProps}
                // scroll={{y: window.innerHeight - 210}}
                // pagination={false}
                   size={"small"}
                   rowKey="id"
                   onRow={(record, rowIndex) => {
                       return {
                           onClick: () => {

                           },
                           onDoubleClick: () => {
                               edit("analisis", record.id);
                           }
                       };
                   }}
            >
                {/*<Table.Column ellipsis dataIndex="id" title="ID" sorter={true}/>*/}
                <Table.Column ellipsis dataIndex="nomeAnalisi" title="Nome Analisi" sorter={true}/>
                <Table.Column ellipsis dataIndex="txtSql" title="Query" sorter={true}/>
                <Table.Column ellipsis dataIndex="isExcel" title="Excel" sorter={true}
                              render={(isExcel) => (
                                  <BooleanField
                                      value={isExcel}
                                      trueIcon={<CheckOutlined />}
                                      falseIcon={<></>}
                                      valueLabelTrue="si"
                                      valueLabelFalse="no"
                                  />
                              )}
                />
                <Table.Column ellipsis dataIndex="isKPI" title="KPI" sorter={true}
                              render={(isKPI) => (
                                  <BooleanField
                                      value={isKPI}
                                      trueIcon={<CheckOutlined />}
                                      falseIcon={<></>}
                                      valueLabelTrue="si"
                                      valueLabelFalse="no"
                                  />
                              )}
                />
                <Table.Column<IAnalisi>
                    title="Actions"
                    dataIndex="actions"
                    // fixed={"right"}
                    width={100}
                    render={(_, record) => {
                        return (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <Button type="primary" icon={<PlaySquareFilled/>} size={"small"}
                                        onClick={() => show("analisis", record.id)}/>
                            </Space>
                        );
                    }}
                />
                {/*<Table.Column*/}
                {/*    dataIndex="status"*/}
                {/*    title="Status"*/}
                {/*    render={(value: string) => <TagField value={value} />}*/}
                {/*/>*/}
            </Table>
        </List>

    );
};
