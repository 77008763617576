import {ReactNode, useState} from "react";
import {io} from "socket.io-client";
import {LiveEventSocketCtx} from "./index";
import {API_URL} from "../../constants";

const socket = io(API_URL, {
    'reconnection': true,
    'reconnectionDelay': 500,
    'reconnectionAttempts': 10
});

const LiveEventSocketCtxProvider = (props: { children?: ReactNode }) => {
    // const socket = ;
    // const [socket, setSocket] = useState<any>(io(API_URL, {reconnectionAttempts: 1000}));

    const [event, setEvent] = useState<any>('');
    const [eventData, setEventData] = useState<any>('');

    socket.onAny((eventName: string, data: any,) => {
        // console.log('DATI DAL PROVIDER', eventName, data);
        setEvent(eventName);
        setEventData(data)
    });


    return (
        <LiveEventSocketCtx.Provider value={{ event: event, data: eventData}}>
            {props.children}
        </LiveEventSocketCtx.Provider>
    );
};

export default LiveEventSocketCtxProvider;
