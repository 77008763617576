import {IRole, IUser} from "interfaces";
import React, {useState} from "react";
import {IResourceComponentsProps, useCreate, useCustom, useTranslate} from "@refinedev/core";
import {Create, useForm, useSelect} from "@refinedev/antd";
import {Col, Form, Input, InputNumber, Row, Select, Switch} from "antd";
import {API_URL} from "../../constants";


export const CreateUser: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {formProps, saveButtonProps} = useForm<IUser>({});

    const {data: queryResultRole} = useCustom({
        url: `${API_URL}/api/users-permissions/roles`,
        method: 'get'
    });

    const options = queryResultRole?.data['roles'].map((x: any) => {
        return ({
            label: x.name,
            value: x.id
        })
    });

    const [ruoloSelezionato, setRuoloSelezionato] = useState<any>();



    const save = useCreate<IUser>();

    const finish = (body: any) => {
        body.role = 1;
        console.log(body);
        save.mutate({
            resource: 'users',
            values: body
        });
    }

    return (
        <Create saveButtonProps={saveButtonProps}
                title={translate('pages.users.titleCreate', 'New User')}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={finish}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Ruolo"
                            name={'role'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select options={options}
                                    onChange={(value) => {
                                        console.log(value);
                                        setRuoloSelezionato(value);
                                    }}>
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={translate('pages.users.stato', 'Bloccato')}
                            name="blocked"
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label={translate('pages.users.nome', 'Name')}
                            name="nome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            // wrapperCol={{span: 14}}
                            label={translate('pages.users.cognome', 'Cognome')}
                            name="cognome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>

                    <Col span={12}>
                        <Form.Item
                            label={translate('pages.users.username', 'User Name')}
                            name="username"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={translate('pages.users.password', 'Password')}
                            name="password"
                            rules={[{
                                required: true,
                            },]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            initialValue={0}
                            label="Costo Orario Effettivo"
                            name="costoOraEffettivo"
                            rules={[
                                {
                                    required: true,
                                    type: "number"
                                },
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            initialValue={0}
                            label="Costo Minuto Effettivo"
                            name="costoMinutoEffettivo"
                            rules={[
                                {
                                    required: true,
                                    type: "number"
                                },
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item
                            label="Telegram ON"
                            name="isTelegramReminderOn"
                            rules={[
                                {
                                    type: "boolean"
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                                onChange={() => {

                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="ID Telegram"
                            name="telegramId"
                            rules={[
                                {
                                    type: "string"
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Email ON"
                            name="isEmailReminderOn"
                            rules={[
                                {
                                    type: "boolean"
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                                onChange={() => {

                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={translate('pages.users.email', 'Email')}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
