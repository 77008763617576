import { IStatoRegistrazione} from "interfaces";
import {StatoRegistrazioneList} from "./list";
import {IResourceComponentsProps} from "@refinedev/core";
import {Edit, useForm} from "@refinedev/antd";
import {Form, Input, Select} from "antd";

export const StatoRegistrazioneEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IStatoRegistrazione>({
    });


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    // wrapperCol={{span: 14}}
                    label="Descrizione"
                    name="descStatoRegistrazione"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Chiudi Precedente"
                    name="isChiudiPrecedente"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
                <Form.Item
                    label="Fatturabile"
                    name="isFatturabile"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
                <Form.Item
                    label="Fatturato"
                    name="isFatturato"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                label: "SI",
                                value: true,
                            },
                            {
                                label: "NO",
                                value: false,
                            },
                        ]}
                        defaultValue={true}
                    />
                </Form.Item>
            </Form>
        </Edit>
    );
};
