import React, {useContext} from "react";

import {TotaleNotificheContext} from "../totaleNotificheProvider";
import {useGetIdentity} from "@refinedev/core";
import {Button, Card, Col, Row, Space, Typography} from "antd";

const {Text} = Typography;

export const DashboardPage: React.FC = () => {
    const [totReminder, setTotReminder] = useContext(TotaleNotificheContext);

    const {data, isLoading} = useGetIdentity<{ address: string, balance: string }>();

    function testNotifiche() {
        setTotReminder(34)
    }

    return (
            <Row gutter={24}>
                <Col span={12}>

                    <Card
                        title="Ethereum Public ID"
                        style={{height: "150px", borderRadius: "15px"}}
                        headStyle={{textAlign: "center"}}
                    >
                        <Space align="center" direction="horizontal">
                            <Text>{isLoading ? "loading" : data?.address}</Text>
                        </Space>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        title="Account Balance"
                        style={{height: "150px", borderRadius: "15px"}}
                        headStyle={{textAlign: "center"}}
                    >
                        <Text>{`${isLoading ? "loading" : data?.balance} Ether`}</Text>
                    </Card>
                </Col>

                <Col span={12}>
                    <Space>
                    <Button onClick={testNotifiche}>CLICCA QUI PER FAR COMPARIRE IL BADGE NOTIFICHE</Button>
                    </Space>
                </Col>
            </Row>
    );
};
