import React from "react";
import {ICliente} from "../../interfaces";
import {IResourceComponentsProps, useExport} from "@refinedev/core";
import {CloneButton, CreateButton, DeleteButton, EditButton, ExportButton, List, useTable} from "@refinedev/antd";
import {Space, Table} from "antd";

export const ClientiList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps} = useTable<ICliente>({
        hasPagination: false,
        syncWithLocation: true,
        metaData: {
            populate: '*'
        }
    });


    const {triggerExport, isLoading: exportLoading} = useExport<ICliente>();


    return (
        <List
            title="Clienti"
            headerButtons={<Space>
                <CreateButton/>,
                <ExportButton
                    onClick={triggerExport}
                    loading={exportLoading}
                />
            </Space>}

        >
            <Table size="small" {...tableProps} rowKey="id"
                   pagination={{
                       ...tableProps.pagination,
                       showSizeChanger: true,
                   }}>
                <Table.Column dataIndex="id" key="id" title="ID"/>
                <Table.Column
                    dataIndex="descCliente"
                    key="descCliente"
                    title="Ragione Sociale"
                />
                <Table.Column
                    dataIndex={["rifNatura", "descNatura"]}
                    title="Natura"
                    key="rifNatura"
                />
                <Table.Column
                    dataIndex={["rifTipoContabilita", "descTipoContabilita"]}
                    title="Tipo Contabilita"
                    key="rifTipoContabilita"
                />
                <Table.Column
                    dataIndex={["rifCassaProfessionale", "descCassaProfessionale"]}
                    title="Cassa Professionale"
                    key="rifCassaProfessionale"
                />
                <Table.Column dataIndex="codiceTSPaghe" key="codiceTSPaghe" title="Codice T.S. Paghe"/>
                <Table.Column<{ id: string }>
                    title="Azioni"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <CloneButton hideText size="small" recordItemId={record.id}/>
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
