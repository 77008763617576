import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import {IStatoRegistrazione} from "../../interfaces";
import {BooleanField, CloneButton, DeleteButton, EditButton, List, useTable} from "@refinedev/antd";
import {Space, Table} from "antd";
import * as Icons from "@ant-design/icons";

export const StatoRegistrazioneList: React.FC<IResourceComponentsProps> = () => {
    const {tableProps} = useTable<IStatoRegistrazione>({
        resource: "stato-registraziones",

    });



    return (
        <List title="Stato Registrazione">
            <Table size="small" {...tableProps} rowKey="id"
                   pagination={{
                       ...tableProps.pagination,
                       showSizeChanger: true,
                   }}>
                <Table.Column dataIndex="id" key="id" title="ID"/>
                <Table.Column
                    dataIndex="descStatoRegistrazione"
                    key="descStatoRegistrazione"
                    title="Descrizione"
                />
                <Table.Column
                    dataIndex="isChiudiPrecedente"
                    title="Chiudi Precedente"
                    render={(value) => (
                        <BooleanField
                            value={value === true}
                            trueIcon={<Icons.CheckCircleOutlined />}
                            falseIcon={<Icons.CloseCircleOutlined />}
                        />
                    )}
                />
                <Table.Column
                    dataIndex="isFatturabile"
                    title="Fatturabile"
                    render={(value) => (
                        <BooleanField
                            value={value === true}
                            trueIcon={<Icons.CheckCircleOutlined />}
                            falseIcon={<Icons.CloseCircleOutlined />}
                        />
                    )}
                />
                <Table.Column
                    dataIndex="isFatturato"
                    title="Fatturato"
                    render={(value) => (
                        <BooleanField
                            value={value === true}
                            trueIcon={<Icons.CheckCircleOutlined />}
                            falseIcon={<Icons.CloseCircleOutlined />}
                        />
                    )}
                />
                <Table.Column<{ id: string }>
                    title="Azioni"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <CloneButton hideText size="small" recordItemId={record.id} />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
