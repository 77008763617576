import React, {useEffect, useState} from "react";
import {INatura, IRegistrazione, ITipoContabilita} from "interfaces";
import {CreateRegistrazioni} from "./create";
import {API_URL, TOKEN_KEY} from "../../constants";
import {IResourceComponentsProps, useDelete} from "@refinedev/core";
import {Button, Col, Form, Input, InputNumber, Row, Select, Table, Upload, UploadProps} from "antd";
import {Edit, useForm, useSelect} from "@refinedev/antd";
import * as Icons from "@ant-design/icons";
export const RegistrazioniEdit: React.FC<IResourceComponentsProps> = () => {
    const { Column } = Table;

    const {formProps, saveButtonProps,queryResult} = useForm<IRegistrazione>({
        metaData: {populate: '*'}
    });

    const removeFile = useDelete();
    const [allegati, setAllegati] = useState<any>([]);


    const {selectProps: naturaSelectProps} = useSelect<INatura>({
        resource: "naturas",
        optionLabel: "descNatura",
        optionValue: "id",
    });

    const {selectProps: tipoContabilitaSelectProps} = useSelect<ITipoContabilita>({
        resource: "tipo-contabilitas",
        optionLabel: "descTipoContabilita",
        optionValue: "id",
    });

    const uploadProps: UploadProps = {
        name: 'files',
        multiple: true,
        action: API_URL + 'api/upload',
        showUploadList: false,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
        onChange(info: any) {
            const {status} = info.file;
            if (status !== 'uploading') {
                queryResult?.data?.data.documentiAllegati.push(info.file.response[0]);
                setAllegati([...allegati])
            }
            if (status === 'done') {
                console.log(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const handleVisibleChange = (newVisible: boolean, file: string) => {
        setAllegati(file);
        window.open(API_URL + file)
    };

    const handleRemove = (file: any) => {
        console.log(file);
        const i: any = queryResult?.data?.data.documentiAllegati.findIndex(x => x.url === file);
        console.log("File: ", i);
        removeFile.mutate({
            resource: "upload/files",
            id: Number(queryResult?.data?.data.documentiAllegati[i].id)
        })
        if (removeFile.isSuccess) {
            queryResult?.data?.data.documentiAllegati.splice(i, 1);
            console.log(queryResult?.data?.data.documentiAllegati);
            setAllegati([...allegati])
        }

    };

    useEffect(() => {
        setAllegati(queryResult?.data?.data.documentiAllegati);
    })

    return (
        <Edit saveButtonProps={saveButtonProps}
              headerButtons={({ defaultButtons }) => (
                  <>
                      {defaultButtons}
                      <Upload {...uploadProps} >
                          <Button icon={<Icons.FileAddOutlined />}>Carica Allegato</Button>
                      </Upload>

                  </>
              )}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Form.Item
                    label="Descrizione Listino"
                    name="descListinoBase"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Descrizione Attività"
                    name="descAttivitaFoglia"
                >
                    <Input/>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Tipo Contabilità"
                            name={["rifTipoContabilita", "id"]}>
                            <Select
                                {...tipoContabilitaSelectProps}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Natura"
                            name={["rifNatura", "id"]}>
                            <Select
                                {...naturaSelectProps}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Con Paghe"
                            name="isConPaghe">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Contabilita Esterna"
                            name="isContabilitaEsterna">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Contabilita Interna"
                            name="isContabilitaInterna">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Privato"
                            name="isPrivato">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Gestione Costo/Ora"
                            name="isCostoOra">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Costo Fisso"
                            name="isCostoFisso">
                            <Select
                                options={[
                                    {
                                        label: "SI",
                                        value: true,
                                    },
                                    {
                                        label: "NO",
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label="Costo Orario"
                            name="euroOraListino">
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Costo al miuto"
                            name="euroMinutoListino"
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Euro Corpo Listino"
                            name="euroCorpoListino"
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                addonBefore="€"
                                defaultValue={0}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
