import {
    ICassaProfessionale,
    ICliente,
    INatura, IPosizionePrevidenziale,
    IRegistrazione, IStatoRegistrazione,
    ITipoContabilita,
    ITipoDichiarazione,
    ITipoLiquidazioneIva,
    IUser
} from "interfaces";
import React, {CSSProperties, useState} from "react";
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select, Space,
    Switch,
    Table,
    Tabs,
    TabsProps,
    TreeSelect,
    Typography
} from "antd";
import {IResourceComponentsProps, useList} from "@refinedev/core";
import {Create, useForm, useSelect} from "@refinedev/antd";
import {
    ContainerTwoTone,
    EuroOutlined,
    EuroTwoTone,
    FileTextOutlined,
    FolderOpenTwoTone,
    PaperClipOutlined
} from "@ant-design/icons";
import * as Icons from "@ant-design/icons";
import Column from "antd/es/table/Column";

const {Text} = Typography;
const { TextArea } = Input;
export const myTable: CSSProperties = {
    width: '100%',
    fontWeight: 'normal',
    // color: 'lightgrey',
    fontSize: '12px',
    fontFamily: 'Montserrat',

};
export const th: CSSProperties = {
    width: '50%',
    textAlign: 'left',
    background: 'rgba(0,0,0,0.06)',
    border: '1px solid lightgrey',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: 'normal'
};

export const td: CSSProperties = {
    width: '50%',
    textAlign: 'right',
    border: '1px solid lightgrey',
};

export const CreateRegistrazioni: React.FC<IResourceComponentsProps> = () => {

    const dateFormat = 'DD/MM/YYYY';
    const {formProps, saveButtonProps} = useForm<IRegistrazione>({
        meta: {
            populate: '*'
        }
    });
    const [clienteSelezionato, setClienteSelezionato] = useState<ICliente>();

    const {selectProps: usersSelectProps} = useSelect<IUser>({
        resource: "users",
        optionLabel: "username",
        optionValue: "id",
    });

    const {selectProps: clienteSelectProps, queryResult: datiCliente} = useSelect<ICliente>({
        resource: "clientes",
        optionLabel: "descCliente",
        optionValue: "id",
        metaData: {populate: '*'},
    });

    const {selectProps: statoRegistrazioneSelectProps} = useSelect<IStatoRegistrazione>({
        resource: "stato-registraziones",
        optionLabel: "descStatoRegistrazione",
        optionValue: "id",
    });

    const onChangeCliente = (value: any) => {
        const d = datiCliente.data?.data.find(x => x.id === value);
        setClienteSelezionato(d);
        formProps.form?.setFieldsValue(['rifNatura', d!.rifNatura])
        console.log('DOPO', d);
    };

    const {refetch: refetchAttivita, data: datiAttivita} = useList({
        resource: 'getAlberoAttivita',
        pagination: {
            mode: 'off'
        }
    })

    const onChangeAttivita = (value: any) => {
        console.log(value)
    }

    const tabellaCliente = () => (
        <table style={myTable}>
            <tr>
                <th colSpan={2}>Informazioni Cliente</th>
            </tr>
            <tr>
                <th style={th}>Utente Default</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.rifUser.username ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isFamiliare</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isFamiliare ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isVies</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isVies ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isVies</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isVies ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isCONAI</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isCONAI ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isGestionePec</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isGestionePec ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isGestioneFirmaDigitale</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isGestioneFirmaDigitale ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isOrganoDiControllo</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isOrganoDiControllo ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Tipo Contabilita</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.rifTipoContabilita.descTipoContabilita ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Natura</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.rifNatura.descNatura ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isContabilitaInterna</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isContabilitaInterna ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isContabilitaEsterna</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isContabilitaEsterna ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isConPaghe</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isConPaghe ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>isPrivato</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.isPrivato ? 'SI' : 'NO') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Tipo Dichiarazione</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.rifTipoDichiarazione.descTipoDichiarazione ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Tipo Liquidazione iva</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.rifTipoLiquidazioneIva.descTipoLiquidazioneIva ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Posizione Previdenziale</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.rifPosizionePrevidenziale.descPosizionePrevidenziale ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Cassa Professionale</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.rifCassaProfessionale.descCassaProfessionale ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Codice TS PAGHE</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.codiceTSPaghe ?? ' -- ') : ' -- '}</td>
            </tr>
            <tr>
                <th style={th}>Codice TS PAGHE</th>
                <td style={td}>{clienteSelezionato !== null ? (clienteSelezionato?.codiceTSPaghe ?? ' -- ') : ' -- '}</td>
            </tr>
        </table>
    );


    const form = () => (<Form
        {...formProps}
        layout="vertical"
    >
        <Row gutter={24}>
            <Col span={20}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="NR. Fascicolo"
                            name="nFascicolo"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Data Reg. Prec."
                            name="dataRegistrazione"
                        >
                            <DatePicker style={{width: '100%'}} format={dateFormat}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Nome e Cognome"
                            name={'nomeCognome'}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Attività"
                            name="nomeCognome"
                        >
                            <TreeSelect
                                showSearch
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 600, overflow: 'auto'}}
                                allowClear
                                treeDefaultExpandAll
                                onChange={onChangeAttivita}
                                treeData={datiAttivita?.data}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Data Registrazione"
                            name="dataRegistrazione"
                        >
                            <DatePicker style={{width: '100%'}} format={dateFormat}/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Utente di default"
                            name={["rifUser", "id"]}>
                            <Select
                                {...usersSelectProps}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Stato Registrazione"
                            name={["rifStatoRegistrazione", "id"]}>
                            <Select
                                {...statoRegistrazioneSelectProps}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={14}>
                        <Form.Item
                            label="Cliente"
                            name={["rifCliente", "id"]}>
                            <Select
                                {...clienteSelectProps}
                                onChange={onChangeCliente}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="Chiudi Precedenti"
                                    name={'isChiudiPrecedenti'}>
                                    <Switch/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Fatturabile"
                                    name={'isFatturabile'}>
                                    <Switch/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Fatturato"
                                    name={'isFatturato'}>
                                    <Switch/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={18}>
                        <Form.Item
                            label="Attività"
                            name="nomeCognome"
                        >
                            <TreeSelect
                                showSearch
                                style={{width: '100%'}}
                                dropdownStyle={{maxHeight: 600, overflow: 'auto'}}
                                allowClear
                                treeDefaultExpandAll
                                onChange={onChangeAttivita}
                                treeData={datiAttivita?.data}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Minuti Attività"
                            name="miutiAttivita"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>

                    <Col span={24}>
                        <Form.Item
                            label="Note Attività"
                            name="noteAttivita"
                        >
                            <TextArea rows={4}></TextArea>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Euro Ora Cliente"
                            name="euroOraCliente"
                        >
                            <div className={'myButton'}><EuroOutlined/>{formProps.form?.getFieldValue('euroOraCliente')}</div>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Euro Minuto Cliente"
                            name="euroMinutoCliente"
                        >
                            <div className={'myButton'}><EuroOutlined/>{formProps.form?.getFieldValue('euroMinutoCliente')}</div>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Euro Corpo Cliente"
                            name="euroCorpoCliente"
                        >
                            <div className={'myButton'}><EuroOutlined/>{formProps.form?.getFieldValue('euroCorpoCliente')}</div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Costi Dare"
                            name="costiDare"
                        >
                            <div className={'myButton'}><EuroOutlined/>{formProps.form?.getFieldValue('costiDare')}</div>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Costi Avere"
                            name="costiAvere"
                        >
                            <div className={'myButton'}><EuroOutlined/>{formProps.form?.getFieldValue('costiAvere')}</div>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Costi Anticipati"
                            name="costiAnticipati"
                        >
                            <InputNumber style={{width: '100%'}} prefix={<EuroOutlined />}></InputNumber>
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={4}>
                {tabellaCliente()}
            </Col>
        </Row>
    </Form>);

    const allegati = () => (
        <Table pagination={false}>
            <Column title="File Name" dataIndex="name" key={Math.floor(Math.random() * (5 - 1 + 1)) + 1} />
            <Column title="" dataIndex="url" render={(value) => (
                <>
                    <Space>
                        <Button key={'btn1'} type="primary" shape="circle"  icon={<Icons.EyeOutlined />} onClick={() => {}}/>
                        <Button key={'btn2'} type="primary" shape="circle" icon={<Icons.DeleteOutlined />} onClick={() => {}}/>
                    </Space>
                </>
            )}
            />
        </Table>
    )

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: <span><FileTextOutlined/>Dati Registrazione</span>,
            children: form(),
        },
        {
            key: '2',
            label: <span><PaperClipOutlined/>File Allegati</span>,
            children: allegati(),
        },
    ];
    return (
        <Create saveButtonProps={saveButtonProps}>
            <Tabs
                defaultActiveKey="1"
                items={tabItems}
            />
        </Create>
    );
};
